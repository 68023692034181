<template>
  <div class="container-fluid">
  <CCard>
    <CCardHeader>
        <CRow>
          <CCol>
            <div class="hello">Dashboard List</div>
          </CCol>
          <!-- <CCol col="2">
            <CLink
              href="../../assets/samplefiles/user_import_sample.xlsx"
              target="_blank"
              v-show="false"
              id="downloadsample"
              ><CIcon name="cil-arrow-bottom" />Download File</CLink
            >
            <CButton
              block
              color="info"
              onclick="document.getElementById('downloadsample').click()"
              Download File</CButton
            >
          </CCol> -->
          <CCol col="2">
            <vue-excel-xlsx
              class="vue-excel-btn"
              :data="voca"
              :columns="columns"
              :file-name="'Dashboard List'"
              :file-type="'xlsx'"
              :sheet-name="'Dashboard List'"
              >Download</vue-excel-xlsx
            >
          </CCol>
         
        </CRow>
      </CCardHeader>
   <CCardBody>
    <CRow>
      <CCol sm="12" md="6">
        <CCard>
          <CCardBody>
            <CCardTitle>Total User Used Vocabulary Bank</CCardTitle>
            <hr />
            <h1 class="text-center" style="cursor: pointer;" @click="goTouserlist()">
              {{ voca[0].totaluserusevoca }}
            </h1>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="12" md="6">
        <CCard>
          <CCardBody>
            <CCardTitle> Total Vocabulary Bank</CCardTitle>
            <hr />
            <h1 class="text-center" style="cursor: pointer;" @click="gotoadminvocabularylist()">
               {{ voca[0].totalvoca }}
            </h1>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
      </CCardBody>
       </CCard>
  </div>
</template>
<script>
import APIService from "@/services/api.service.js";

export default {
  name: "Dashboardvoca",
  data() {
    return {
        columns: [
        {
          label: "Total User",
          field: "totaluserusevoca",
        },
        {
          label: "Total Vocabulary",
          field: "totalvoca",
        },
      ],
      voca: {},
    };
  },
  created() {
    this.getVocabularyCountData();
    document.title = this.$store.state.projecttitle + " - Dashboard";
  },
  methods: {
    goTouserlist() {
      this.$router.push({ name: "User List" });
    },
    gotoadminvocabularylist() {
      this.$router.push({ name: "Vocabulary List" });
    },
    getVocabularyCountData() {
      APIService.getVocabulary("/vocabularies/getvocabularycount", null).then(
        ((response) => {
          this.$set(this, "voca", response.data);
        }).bind(this)
      );
    },
  },
};
</script>

<style scoped>
.vue-excel-btn {
   outline: none;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #636f83;
  border-color: #636f83;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

</style>